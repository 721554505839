var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"publicNav flex",class:[_vm.deviceInfo.type],style:({
    background: `linear-gradient(90deg,${_vm.currentSkin.topBg[0]} 0%,${_vm.currentSkin.topBg[1]} 100%)`,
  })},[_c('div',{staticClass:"min-480 title flex"},[(_vm.layoutMode == 'navFull')?_c('span',{style:({
        'display': 'flex',
        'align-item': 'center',
        color: _vm.currentSkin ? _vm.currentSkin.topColor : '',
      })},[_c('div',{staticClass:"flex mgl10",staticStyle:{"font-size":"30px","letter-spacing":"1px"}},[_vm._v(_vm._s(_vm.$t("public.main_title")))])]):_vm._e(),(_vm.showBreadcrumb)?_c('el-breadcrumb',{staticStyle:{"margin-left":"10px"},attrs:{"separator":"/"}},_vm._l((_vm.breadcrumb),function(item){return _c('el-breadcrumb-item',{key:item.name},[_c('em',{staticClass:"breadcrumbSpan",style:({
            color: _vm.currentSkin ? _vm.currentSkin.topColor : '',
          })},[_vm._v(" "+_vm._s(item.label)+" ")])])}),1):_vm._e()],1),(_vm.navMode != 'default')?_c('div',{staticClass:"min-480 topNav"},[_c('el-menu',{staticClass:"el-menu-demo",attrs:{"default-active":_vm.activeIndex,"mode":"horizontal","background-color":_vm.currentSkin.topBg[0],"text-color":"#ffffff","active-text-color":"#ffffff"},on:{"select":_vm.handleSelect}},_vm._l((_vm.nav),function(item){return _c('el-menu-item',{key:item.name,attrs:{"index":item.name}},[_vm._v(_vm._s(item.meta.title))])}),1)],1):_vm._e(),_c('div',{staticClass:"max-480 flex small"},[_c('div',{staticClass:"flex small-icon",class:{ flexd: _vm.isSideOpen },on:{"click":_vm.toggleOpenSide}},[(!_vm.isSideOpen)?_c('i',{staticClass:"el-icon-menu",style:({
          color: _vm.currentSkin ? _vm.currentSkin.topColor : '',
        })}):_c('i',{staticClass:"el-icon-error",style:({
          color: _vm.currentSkin ? _vm.currentSkin.topColor : '',
          fontSize: '20px',
        })})]),_c('el-popover',{attrs:{"placement":"bottom","trigger":"click"}},[_c('div',{staticClass:"langList"},[_c('h3',[_vm._v(_vm._s(_vm.$t("public.language")))]),_vm._l((_vm.langEnum),function(lang){return _c('div',{key:lang.type,staticClass:"flex",class:{ langListCheck: lang.isCheck },on:{"click":function($event){return _vm.handleChangeLang(lang)}}},[_c('img',{attrs:{"src":lang.icon,"alt":""}}),_c('span',[_vm._v(" "+_vm._s(lang.label)+" ")])])})],2),_c('div',{staticClass:"flex langBox",attrs:{"slot":"reference"},slot:"reference"},[_c('img',{attrs:{"src":_vm.showLang.icon,"alt":""}}),_c('span',{staticClass:"omit-1"},[_vm._v(_vm._s(_vm.showLang.label))]),_c('i',{staticClass:"el-icon-arrow-down"})])])],1),_c('div',{staticClass:"max-480 small-title",style:({
      color: _vm.currentSkin ? _vm.currentSkin.topColor : '',
    })},[_vm._v(" SN：1121112095 ")]),_c('div',{staticClass:"account flex"},[_c('el-popover',{attrs:{"placement":"bottom","trigger":"click"}},[_c('div',{staticClass:"langList"},[_c('h3',[_vm._v(_vm._s(_vm.$t("public.language")))]),_vm._l((_vm.langEnum),function(lang){return _c('div',{key:lang.type,staticClass:"flex",class:{ langListCheck: lang.isCheck },on:{"click":function($event){return _vm.handleChangeLang(lang)}}},[_c('img',{attrs:{"src":lang.icon,"alt":""}}),_c('span',[_vm._v(" "+_vm._s(lang.label)+" ")])])})],2),_c('div',{staticClass:"flex langBox",attrs:{"slot":"reference"},slot:"reference"},[_c('img',{attrs:{"src":_vm.showLang.icon,"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.showLang.label))])])]),_c('el-popover',{attrs:{"placement":"bottom-start","trigger":"click","popper-class":"select-popover"},on:{"show":_vm.toggleChangeIcon,"hide":_vm.toggleChangeIcon}},[_c('div',{staticClass:"account-select"},_vm._l((_vm.selects),function(item){return _c('div',{key:item.type,staticClass:"select-one flex",on:{"click":function($event){return _vm.handleClick(item)}}},[_c('i',{class:item.icon}),_c('span',[_vm._v(" "+_vm._s(item.label)+" ")])])}),0),_c('div',{staticClass:"flex account-info",attrs:{"slot":"reference"},slot:"reference"},[_c('span',{staticStyle:{"color":"#ffffff","margin":"0 6px"}},[_vm._v(_vm._s(_vm.userName))]),_c('i',{staticClass:"iconfont icon-xiajiantou",class:{ iconOpen: _vm.isOpen },style:({
            color: _vm.currentSkin ? _vm.currentSkin.topColor : '',
          })})])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }